.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
  background: url(../../assets/img/logo.png);
}

.site-layout .site-layout-background {
  background: #fff;
}

/* table,
th,
td {
    border: 1px solid rgb(48, 46, 46);
    border-collapse: collapse;
} */
